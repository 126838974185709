<template>
  <app-list-view
    server-side
    app="activity"
    model="masterdataactivityprofileheader"
    api-url="activity/master-data-activity-profile-header/"
    :title="$t('menu.masterActivityProfileTemplate')"
    :createTo="{ name: 'masterActivityProfileTemplateCreate' }"
    :editTo="{ name: 'masterActivityProfileTemplateEdit' }"
    :headers="headers"
  >
  </app-list-view>
</template>

<script>
import AppListView from '@components/AppListView'

export default {
  name: 'masterActivityProfileTemplateList',
  components: {
    AppListView
  },
  computed: {
    headers() {
      return [
        { text: this.$t('fields.templateName'), value: 'name' },
        {
          text: this.$t('fields.activityType'),
          value: 'activity_type_id.name'
        },
        {
          text: this.$t('fields.active'),
          value: 'active',
          check: 'true',
          groupable: false,
          hideFilter: true
        },
        {
          text: this.$t('label.action'),
          value: 'actions',
          sortable: false,
          hideFilter: true
        }
      ]
    }
  },
  created() {
    this.$store.dispatch('addBreadcrumb', {
      name: 'masterActivityProfileTemplate'
    })
  }
}
</script>
